import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import background_image from "../../img/background_image.jpg";
import { JdBasicInfoItem } from "../../Components/JobPosts/JobDescription";

import {
  getUserProfileDataAll,
  updateUserCourseData,
  updateUserEducationData,
  updateUserExperienceData,
  updateUserProfileData,
  updateUserReferenceData,
  updateUserSkillData,
} from "../../ApiCalls/User";
import { Backdrop, Chip, CircularProgress, Stack } from "@mui/material";
import { SingleEducation } from "../../Components/User/SingleEducation";
import { SingleExperience } from "../../Components/User/SingleExperience";
import { SingleCourse } from "../../Components/User/SingleCourse";
import { SingleReference } from "../../Components/User/SingleReference";
import { PictureInput } from "../../Components/Public/InputField";
import { createClient } from "@supabase/supabase-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { JobFindInfoEditModal } from "../../Modals/User/JobFindInfoModal";
import { PersonalInfoEditModal } from "../../Modals/User/PersonalInfoModal";
import { UserEducationEditModal } from "../../Modals/User/UserEducationModal";
import { UserReferenceModal } from "../../Modals/User/UserReferenceModal";
import { UserCourseModal } from "../../Modals/User/UserCourseModal";
import { UserPreExperienceModal } from "../../Modals/User/UserPreExperienceModal";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
// import { Dropdown, DropdownButton } from "react-bootstrap";
import { FiMoreVertical } from "react-icons/fi";
// import { Form } from "react-router-dom";
import axios from "axios";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router";
import { UserSkillInfoEdit } from "../../Components/CvMaker/UserSkillInfo";
import { SkillInfoEditModal } from "../../Modals/User/UserSkillInfoModal";

const Profile = () => {
  const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_API_KEY
  );
  const navigate = useNavigate();
  const [educations, setEducations] = useState([]);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [universitys, setUniversitys] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [courses, setCourses] = useState([]);
  const [skills, setSkills] = useState([]);
  const [profileDatas, setProfileDatas] = useState({});

  const [jobFindInfo, setJobFindInfo] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [cvInfo, setCVInfo] = useState({});
  const [educationInfo, setEducationInfo] = useState([]);
  const [preExperienceInfo, setPreExperienceInfo] = useState([]);
  const [courseInfo, setCourseInfo] = useState([]);
  const [skillInfo, setSkillInfo] = useState([]);
  const [referanceInfo, setReferenceInfo] = useState([]);
  const [jobFindInfoEditModalShow, setJobFindInfoEditModalShow] =
    useState(false);

  const [personalInfoEditModalShow, setpersonalInfoEditModalShow] =
    useState(false);
  const [educationEditModalShow, setEducationEditModalShow] = useState(false);
  const [courseEditModalShow, setCourseEditModalShow] = useState(false);
  const [referenceEditModalShow, setReferenceEditModalShow] = useState(false);
  const [preExpEditModalShow, setPreExpEditModalShow] = useState(false);
  const [skillEditModalShow, setSkillEditModalShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [uploadedCV, setUploadedCV] = useState(null);
  const [cvMakerCV, setCvMakerCV] = useState(null);

  const getUserProfileData = async () => {
    const response = await getUserProfileDataAll();
    const data = response.data[0];

    setProfileDatas(response.data[0]);

    setPersonalInfo({
      full_name: { value: data.full_name, is_warning: false },
      email: { value: data.email, is_warning: false },
      date_of_birth: {
        value: dayjs(data.date_of_birth, "YYYY-MM-DD").format("DD/MM/YYYY"),
        is_warning: false,
      },
      current_address: { value: data.current_address, is_warning: false },
      gender: {
        value: data.gender,
        label:
          data.gender === "Male"
            ? "পুরুষ"
            : data.gender === "Female"
            ? "মহিলা"
            : "অন্যান্য",
        is_warning: false,
      },
      phone: { value: data.username, is_warning: false },
    });

    setProfileImage(
      data.profile_photo
        ? process.env.REACT_APP_STORAGE_URL +
            "/shomvob-bucket/" +
            data.profile_photo
        : ""
    );
    try {
      let iuc = true;
      let cccc = "";
      data.cv_data.forEach((item) => {
        if (item.id === 1) {
          setUploadedCV(item.url + "?" + Date.now());
          iuc = false;
        } else if (item.id === 2) {
          setCvMakerCV(item.url + "?" + Date.now());
          cccc = item.url;
        }
      });
      if (iuc) {
        checkCV(cccc);
      } else {
        setOpen(false);
      }
    } catch (error) {
      checkCV("");
      setOpen(false);
      console.log(error);
    }

    extractEducation(
      data.user_education_data.sort((a, b) => {
        let id1 = parseInt(a.education_id, 10);
        let id2 = parseInt(b.education_id, 10);
        if (id1 < id2) return -1;
        if (id1 > id2) return 1;
        return 0;
      })
    );

    setPreExperienceInfo(
      data.user_experience_data ? data.user_experience_data : []
    );
    extractPreExp(data.user_experience_data ? data.user_experience_data : []);

    extractCourse(data.user_courses_data ? data.user_courses_data : []);
    extractReference(data.user_reference_data ? data.user_reference_data : []);
    setSkillInfo(data.user_skills_data ? data.user_skills_data : []);
    setOpen(false);
  };

  const extractEducation = (eduData) => {
    const modifiedEduData = eduData.map((item) => {
      return {
        education: {
          value: item.education_id,
          label: item.education_name,
          is_warning: false,
          is_university_required: item.institute_id > 0,
        },
        institute: {
          value: item.institute_id,
          label: item.institute_name,
          is_warning: false,
        },
        subject: { value: item.subject, is_warning: false },
        is_ongoing: item.is_ongoing,
        end_date: {
          value: !item?.is_ongoing
            ? dayjs(item?.end_date, "YYYY-MM-DD").format("DD/MM/YYYY")
            : null,
          is_warning: false,
        },
      };
    });
    setEducationInfo(modifiedEduData);
  };

  const extractCourse = (courseData) => {
    const modifiedCourseData = courseData.map((item) => {
      return {
        course: {
          value: item.course_id,
          label: item.course_id === 385 ? "Other" : item.course_name,
          is_warning: false,
        },
        other_course: {
          value: item.course_id === 385 ? item.course_name : "",
          is_warning: false,
        },
        organization: {
          value: item.organization,
          is_warning: false,
        },
      };
    });
    setCourseInfo(modifiedCourseData);
  };

  const extractPreExp = (expData) => {
    const modifiedExpData = expData
      ? expData.map((item) => {
          return {
            experience_name: {
              value: item?.experience_name,
              is_warning: false,
            },
            company_name: { value: item?.company_name, is_warning: false },
            start_date: {
              value: dayjs(item?.start_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
              is_warning: false,
            },
            end_date: {
              value: !item?.is_ongoing
                ? dayjs(item?.end_date, "YYYY-MM-DD").format("DD/MM/YYYY")
                : null,
              is_warning: false,
            },
            is_ongoing: item?.is_ongoing,
            job_responsibilities: {
              value: item?.job_responsibilities,
              is_warning: false,
            },
            job_type: {
              value: item?.job_type_id,
              label: item?.job_type_text,
              is_warning: false,
            },
          };
        })
      : [];

    setPreExperienceInfo(modifiedExpData);
  };

  const extractReference = (refData) => {
    const modifiedRefData = refData.map((item) => {
      return {
        name: { value: item?.reference_name, is_warning: false },
        designation: { value: item?.reference_designation, is_warning: false },
        company: { value: item?.reference_company, is_warning: false },
        phone: { value: item?.reference_phone, is_warning: false },
        email: { value: item?.reference_email, is_warning: false },
      };
    });
    console.log(modifiedRefData);
    setReferenceInfo(modifiedRefData);
  };

  useEffect(() => {
    setJobFindInfo((prevJobFindInfo) => {
      let newJobFindInfo = { ...prevJobFindInfo };

      if (profileDatas?.division && divisions.length > 0) {
        newJobFindInfo = {
          ...newJobFindInfo,
          division: {
            label: divisions.find(
              (item) => item.division_id === profileDatas.division
            )?.division,
            value: profileDatas.division,
            is_warning: false,
          },
        };
      }

      if (profileDatas?.district && areas.length > 0) {
        newJobFindInfo = {
          ...newJobFindInfo,
          district: {
            label: areas.find(
              (item) => item.district_id === profileDatas.district
            )?.district,
            value: profileDatas.district,
            is_warning: false,
          },
        };
      }

      if (profileDatas?.job_type_list && jobTypes.length > 0) {
        newJobFindInfo = {
          ...newJobFindInfo,
          job_type: {
            items: jobTypes
              .filter((item) => profileDatas.job_type_list.includes(item.id))
              .map((filteredItem) => ({
                label: filteredItem.job_type,
                value: filteredItem.id,
              })),
            is_warning: false,
          },
        };
      }

      if (profileDatas?.education && educations.length > 0) {
        const tempEducation = educations.find(
          (item) => item.id === profileDatas.education
        );
        newJobFindInfo = {
          ...newJobFindInfo,
          education: {
            label: tempEducation?.education,
            value: profileDatas.education,
            is_university_required: tempEducation?.is_university_required,
            is_warning: false,
          },
        };
      }

      if (profileDatas?.university_id && universitys.length > 0) {
        newJobFindInfo = {
          ...newJobFindInfo,
          university: {
            label: universitys.find(
              (item) => item.id === profileDatas.university_id
            )?.university_name_bn,
            value: profileDatas.university_id,
            is_warning: false,
          },
        };
      }

      if (profileDatas?.work_experience && workExperiences.length > 0) {
        newJobFindInfo = {
          ...newJobFindInfo,
          work_experience: {
            label: workExperiences.find(
              (item) => item.id === profileDatas.work_experience
            )?.work_experience,
            value: profileDatas.work_experience,
            is_warning: false,
          },
        };
      }

      return newJobFindInfo;
    });
  }, [
    educations,
    workExperiences,
    jobTypes,
    universitys,
    divisions,
    areas,
    profileDatas,
  ]);

  const updateProfileData = async (dt, successMsg, errorMsg) => {
    try {
      const response = await updateUserProfileData(dt);
      if (response.status === 200 || response.status === 204) {
        toast.success(successMsg, {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(errorMsg, {
        autoClose: 5000,
      });
    }
  };

  const updateEducationInfoData = async (dt, successMsg, errorMsg) => {
    try {
      const response = await updateUserEducationData(dt);
      if (response.status === 200 || response.status === 204) {
        toast.success(successMsg, {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(errorMsg, {
        autoClose: 5000,
      });
    }
  };

  const updatePreExperienceData = async (dt, successMsg, errorMsg) => {
    try {
      const response = await updateUserExperienceData(dt);
      if (response.status === 200 || response.status === 204) {
        toast.success(successMsg, {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(errorMsg, {
        autoClose: 5000,
      });
    }
  };

  const updateCourseInfoData = async (dt, successMsg, errorMsg) => {
    try {
      const response = await updateUserCourseData(dt);
      if (response.status === 200 || response.status === 204) {
        toast.success(successMsg, {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(errorMsg, {
        autoClose: 5000,
      });
    }
  };

  const updateReferenceInfoData = async (dt, successMsg, errorMsg) => {
    try {
      const response = await updateUserReferenceData(dt);
      if (response.status === 200 || response.status === 204) {
        toast.success(successMsg, {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(errorMsg, {
        autoClose: 5000,
      });
    }
  };
  const updateUserSkillInfoData = async (dt, successMsg, errorMsg) => {
    try {
      const response = await updateUserSkillData(dt);
      if (response.status === 200 || response.status === 204) {
        toast.success(successMsg, {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(errorMsg, {
        autoClose: 5000,
      });
    }
  };

  const imagUpload = async (path, data1) => {
    const token = localStorage.getItem("accessToken");
    const { data, error } = await supabase.storage
      .from("shomvob-bucket")
      .upload(path, data1, {
        cacheControl: "1",
        upsert: false,
        headers: { Authorization: `Bearer ${token}` },
      });

    if (error) {
      console.log(error.message);
    } else {
      const dt = {
        profile_photo:
          process.env.REACT_APP_STORAGE_URL + "shomvob-bucket/" + data.path,
      };
      setProfileImage(
        process.env.REACT_APP_STORAGE_URL + "shomvob-bucket/" + data.path
      );
      updateProfileData(
        dt,
        "ছবি আপলোড সম্পূর্ণ হয়েছে।",
        "ছবি আপলোড ব্যর্থ হয়েছে।"
      );
      console.log(dt);
    }
  };
  const handleImageChange = (fileData) => {
    if (fileData && fileData[0]) {
      let img = fileData[0];
      profileImage
        ? imagUpdate(
            localStorage.getItem("userId") + "/profile/profile_image.jpg",
            img,
            1
          )
        : imagUpload(
            localStorage.getItem("userId") + "/profile/profile_image.jpg",
            img,
            1
          );

      setProfileImage(URL.createObjectURL(img));
    }
  };
  const imagUpdate = async (path, data1, mark) => {
    const token = localStorage.getItem("accessToken");
    const { data, error } = await supabase.storage
      .from("shomvob-bucket")
      .update(path, data1, {
        cacheControl: "1",
        upsert: false,
        headers: { Authorization: `Bearer ${token}` },
      });

    if (error) {
      console.log(error.message);
    } else {
      // console.log(data);

      if (data.path) {
        if (mark === 1) {
          setProfileImage(
            process.env.REACT_APP_STORAGE_URL + "shomvob-bucket/" + data.path
          );
          const dt = {
            profile_photo:
              process.env.REACT_APP_STORAGE_URL + "shomvob-bucket/" + data.path,
          };
          updateProfileData(
            dt,
            "ছবি আপলোড সম্পূর্ণ হয়েছে।",
            "ছবি আপলোড ব্যর্থ হয়েছে।"
          );
          console.log(dt);
        } else {
          toast.success("সিভি আপলোড সম্পূর্ণ হয়েছে।", {
            autoClose: 5000,
          });
        }
      }
    }
  };

  useEffect(() => {
    setOpen(true);
    if (localStorage.getItem("publicTableData")) {
      const publicStringData = localStorage.getItem("publicTableData");
      const publicData = JSON.parse(publicStringData);

      setEducations(
        publicData.education
          .filter((item) => item.is_visible_user_profile)
          .map((item) => {
            return { ...item, value: item.id, label: item.education };
          })
      );
      setWorkExperiences(
        publicData.work_experience
          .filter((item) => item.is_visible_user_profile)
          .map((item) => {
            return { ...item, value: item.id, label: item.work_experience };
          })
      );
      setJobTypes(
        publicData.job_type
          .filter((item) => item.is_visible)
          .map((item) => {
            return { ...item, value: item.id, label: item.job_type };
          })
      );
      setUniversitys(
        publicData.university_list
          .filter((item) => item.is_visible)
          .map((item) => {
            return { ...item, value: item.id, label: item.university_name_bn };
          })
      );
      setDivisions(
        publicData.division
          .filter((item) => item.is_visible)
          .map((item) => {
            return { ...item, value: item.division_id, label: item.division };
          })
      );
      setAreas(
        publicData.district
          .filter((item) => item.is_visible)
          .map((item) => {
            return { ...item, value: item.district_id, label: item.district };
          })
      );
      setCourses(
        publicData.skill_courses
          .filter((item) => item.type === "course")
          .map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.skill_course_name,
            };
          })
      );

      setSkills(
        publicData.skill_courses
          .filter((item) => item.type === "skill")
          .map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.skill_course_name,
            };
          })
      );
    }
    getUserProfileData();
  }, []);
  const checkCV = async (cvMakerCVUrl222) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SUPABASE_URL +
          "/storage/v1/object/sign/shomvob-bucket/" +
          localStorage.getItem("userId") +
          "/cv/cv.pdf",
        {
          expiresIn: 3600,
        },
        {
          headers: {
            "Content-Type": "application/json",
            apikey: process.env.REACT_APP_API_KEY,
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        setUploadedCV(
          process.env.REACT_APP_STORAGE_URL +
            "shomvob-bucket/" +
            localStorage.getItem("userId") +
            "/cv/cv.pdf?" +
            Date.now()
        );
        setOpen(false);
        makeCVDataToUpload(cvMakerCVUrl222);
      } else {
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
      console.error(error);
    }
  };
  const makeCVDataToUpload = (cvMakerCVUrl222) => {
    let cdup = [
      {
        id: 1,
        type: "uploaded_cv",
        url:
          process.env.REACT_APP_STORAGE_URL +
          "shomvob-bucket/" +
          localStorage.getItem("userId") +
          "/cv/cv.pdf",
      },
    ];
    if (cvMakerCVUrl222.length > 5) {
      cdup = [
        ...cdup,
        {
          id: 2,
          type: "cv_maker",
          url: cvMakerCVUrl222,
        },
      ];
    }
    const body = {
      cv_data: cdup,
    };

    updateProfileData(body, false);
  };
  const downloadPdf = (field) => {
    if (field === "uploadedCV") {
      window.open(`${uploadedCV.replace("sign", "public")}`, "_blank");
    } else if (field === "createdCV") {
      window.open(`${cvMakerCV.replace("sign", "public")}`, "_blank");
    }
  };
  const handleDropdownOptionClick = async (option) => {
    switch (option) {
      case "upload":
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".pdf";
        fileInput.onchange = async (event) => {
          const file = event.target.files[0];
          uploadedCV
            ? await handleFileUpdate(file)
            : await handleFileUpload(file);
        };
        fileInput.click();
        break;
      case "create":
        navigate("/CVMaker");
        break;
      default:
        break;
    }
  };
  const handleFileUpdate = async (file) => {
    const { data, error } = await supabase.storage
      .from("shomvob-bucket")
      .update(localStorage.getItem("userId") + "/cv/cv.pdf", file, {
        cacheControl: "1",
        upsert: false,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

    if (error) {
      console.error(error);
      toast.error("সিভি আপডেট ব্যর্থ হয়েছে।", {
        autoClose: 5000,
      });
      return;
    }
    toast.success("সিভি আপলোড সফল হয়েছে।", {
      autoClose: 5000,
    });
  };
  const handleFileUpload = async (file) => {
    setOpen(true);
    const { data, error } = await supabase.storage
      .from("shomvob-bucket")
      .upload(localStorage.getItem("userId") + "/cv/cv.pdf", file, {
        cacheControl: "1",
        upsert: false,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

    if (error) {
      console.error(error);
      toast.error("সিভি আপলোড ব্যর্থ হয়েছে।", {
        autoClose: 5000,
      });
      return;
    }

    setUploadedCV(
      process.env.REACT_APP_STORAGE_URL +
        "shomvob-bucket/" +
        data.path +
        "?" +
        Date.now()
    );
    makeCVDataToUpload(cvMakerCV);
    setOpen(false);
    toast.success("সিভি আপলোড সম্পূর্ণ হয়েছে।", {
      autoClose: 5000,
    });
  };
  const tl = "Shomvob: Jobs & Trainings | Profile";
  const ds2 =
    "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";

  return (
    <>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href="/cvmaker"></link>
        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        {/*End Twitter tags*/}
      </Helmet>
      <React.Fragment>
        <ToastContainer />
        <div
          className=" scroll-container container-fluid"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: "100% auto",
            backgroundRepeat: "repeat-y",
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
          <div className="rounded mb-5" name="Job Details">
            <div className="jd_section">
              <div className="top">
                <PictureInput
                  image={profileImage ? profileImage + "?" + Date.now() : ""}
                  onPictureUpload={(fileData) => {
                    handleImageChange(fileData);
                  }}
                />
              </div>
            </div>

            <div className="jd_section">
              <div className="company_details profile_section">
                <div className="d-flex justify-content-between align-items-center">
                  {" "}
                  <h1>চাকরি খোঁজার তথ্য</h1>{" "}
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setJobFindInfoEditModalShow(true);
                    }}
                  />
                </div>

                <div className="row">
                  <JdBasicInfoItem
                    title={"বিভাগ"}
                    value={jobFindInfo?.division?.label}
                  />
                  <JdBasicInfoItem
                    title={"এলাকা"}
                    value={jobFindInfo?.district?.label}
                  />
                  <JdBasicInfoItem
                    title={"কাজের অভিজ্ঞতা"}
                    value={jobFindInfo?.work_experience?.label}
                  />
                  <JdBasicInfoItem
                    title={"কাজের ধরন"}
                    value={
                      jobFindInfo?.job_type
                        ? jobFindInfo?.job_type?.items
                            .map((item) => item.label)
                            .join(", ")
                        : ""
                    }
                  />

                  <JdBasicInfoItem
                    title={"সর্বোচ্চ শিক্ষাগত যোগ্যতা"}
                    value={jobFindInfo?.education?.label}
                  />
                  {jobFindInfo.university && (
                    <JdBasicInfoItem
                      title={"ইউনিভার্সিটি"}
                      value={jobFindInfo?.university?.label}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="jd_section">
              <div className="company_details profile_section">
                <div className="d-flex justify-content-between align-items-center">
                  {" "}
                  <h1>আপনার সিভি</h1>{" "}
                </div>
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <Form.Group className="cv_maker_form_group">
                      <Form.Label className="profile_form_label">
                        আপলোড করা সিভি
                      </Form.Label>
                      <div
                        className="d-flex align-items-center border"
                        style={{
                          maxWidth: "314px",
                          borderColor: "#ced4da",
                          background: "#F2F9FF",
                          borderWidth: "1px",
                          borderRadius: "5px",
                        }}
                      >
                        <Form.Control
                          type="text"
                          value={
                            uploadedCV ? uploadedCV : "সিভি আপলোড করা হয়নি"
                          }
                          disabled="true"
                          style={{
                            background: "#F2F9FF",
                            border: "none",
                            borderRight: "1px solid #ced4da",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                        />
                        <DropdownButton
                          variant="Light"
                          style={{
                            border: "none",
                          }}
                        >
                          <Dropdown.Item
                            onClick={() => handleDropdownOptionClick("upload")}
                          >
                            আপলোড করুন
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={!uploadedCV}
                            onClick={() => downloadPdf("uploadedCV")}
                          >
                            প্রিভিউ করুন
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={!uploadedCV}
                            onClick={() => downloadPdf("uploadedCV")}
                          >
                            ডাউনলোড করুন
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <Form.Group className="cv_maker_form_group">
                      <Form.Label className="profile_form_label">
                        সম্ভব দিয়ে তৈরি করা সিভি
                      </Form.Label>
                      <div
                        className="d-flex align-items-center border"
                        style={{
                          maxWidth: "314px",
                          borderColor: "#ced4da",
                          background: "#F2F9FF",
                          borderWidth: "1px",
                          borderRadius: "5px",
                        }}
                      >
                        <Form.Control
                          type="text"
                          value={cvMakerCV ? cvMakerCV : "সিভি তৈরি করা হয়নি"}
                          disabled="true"
                          style={{
                            background: "#F2F9FF",
                            border: "none",
                            borderRight: "1px solid #ced4da",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                        />
                        <DropdownButton
                          menuGlyph={<FiMoreVertical />}
                          variant="Light"
                          style={{
                            border: "none",
                          }}
                        >
                          <Dropdown.Item
                            onClick={() => handleDropdownOptionClick("create")}
                          >
                            তৈরি করুন
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={!cvMakerCV}
                            onClick={() => downloadPdf("createdCV")}
                          >
                            প্রিভিউ করুন
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={!cvMakerCV}
                            onClick={() => downloadPdf("createdCV")}
                          >
                            ডাউনলোড করুন
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <div className="jd_section">
              <div className="company_details profile_section">
                <div className="d-flex justify-content-between align-items-center">
                  {" "}
                  <h1>ব্যক্তিগত তথ্য</h1>{" "}
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setpersonalInfoEditModalShow(true);
                    }}
                  />
                </div>
                <div className="row">
                  <JdBasicInfoItem
                    title={"নাম"}
                    value={personalInfo.full_name?.value}
                  />
                  <JdBasicInfoItem
                    title={"জন্ম তারিখ"}
                    value={personalInfo.date_of_birth?.value}
                  />
                  <JdBasicInfoItem
                    title={"লিঙ্গ"}
                    value={personalInfo.gender?.value}
                  />
                  <JdBasicInfoItem
                    title={"ই-মেইল"}
                    value={personalInfo.email?.value}
                  />

                  <JdBasicInfoItem
                    title={"মোবাইল নম্বর"}
                    value={"+" + personalInfo.phone?.value}
                  />

                  <JdBasicInfoItem
                    title={"বাসার ঠিকানা"}
                    value={personalInfo.current_address?.value}
                  />
                </div>
              </div>
            </div>

            <div className="jd_section">
              <div className="company_details profile_section">
                <div className="d-flex justify-content-between align-items-center">
                  {" "}
                  <h1>শিক্ষাগত যোগ্যতা</h1>{" "}
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEducationEditModalShow(true);
                    }}
                  />
                </div>
                <div>
                  {educationInfo.map((item, index) => (
                    <SingleEducation data={item} index={index + 1} />
                  ))}
                </div>
              </div>
            </div>
            <div className="jd_section">
              <div className="company_details profile_section">
                <div className="d-flex justify-content-between align-items-center">
                  {" "}
                  <h1>পূর্ববর্তী অভিজ্ঞতা</h1>{" "}
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPreExpEditModalShow(true);
                    }}
                  />
                </div>
                <div>
                  {preExperienceInfo.map((item, index) => (
                    <SingleExperience data={item} index={index + 1} />
                  ))}
                </div>
              </div>
            </div>
            <div className="jd_section">
              <div className="company_details profile_section">
                <div className="d-flex justify-content-between align-items-center">
                  {" "}
                  <h1>আপনার কোর্স</h1>{" "}
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCourseEditModalShow(true);
                    }}
                  />
                </div>
                <div>
                  {courseInfo.map((item, index) => (
                    <SingleCourse data={item} index={index + 1} />
                  ))}
                </div>
              </div>
            </div>
            <div className="jd_section">
              <div className="company_details profile_section">
                <div className="d-flex justify-content-between align-items-center">
                  {" "}
                  <h1>অন্যান্য দক্ষতা</h1>{" "}
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSkillEditModalShow(true);
                    }}
                  />
                </div>

                <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
                  {skillInfo.map((item) => (
                    <Chip
                      label={item.skill_name}
                      className="mb-2"
                      variant="outlined"
                    />
                  ))}
                </Stack>
              </div>
            </div>
            <div className="jd_section">
              <div className="company_details profile_section">
                <div className="d-flex justify-content-between align-items-center">
                  {" "}
                  <h1>রেফারেন্স</h1>{" "}
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setReferenceEditModalShow(true);
                    }}
                  />
                </div>
                <div>
                  {referanceInfo.map((item, index) => (
                    <SingleReference data={item} index={index + 1} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {jobFindInfoEditModalShow && (
          <JobFindInfoEditModal
            item={jobFindInfo}
            isVisible={jobFindInfoEditModalShow}
            onHide={() => {
              setJobFindInfoEditModalShow(false);
            }}
            divisionOptions={divisions}
            districtOptions={areas}
            experienceOptions={workExperiences}
            jobTypeOptions={jobTypes}
            educationOptions={educations}
            universityOptions={universitys}
            updateJobFindData={(dt, updateData) => {
              setJobFindInfo(updateData);
              updateProfileData(
                dt,
                "চাকরি খোঁজার তথ্য আপডেট সফল হয়েছে।",
                "চাকরি খোঁজার তথ্য আপডেট ব্যার্থ হয়েছে , কিছুক্ষন পরে আবার চেষ্টা করুন।"
              );
              setJobFindInfoEditModalShow(false);
            }}
          />
        )}
        {personalInfoEditModalShow && (
          <PersonalInfoEditModal
            isVisible={personalInfoEditModalShow}
            data={personalInfo}
            onHide={() => {
              setpersonalInfoEditModalShow(false);
            }}
            updatePersonalInfoData={(dt, updateData) => {
              setPersonalInfo(updateData);
              updateProfileData(
                dt,
                "ব্যাক্তিগত তথ্য আপডেট সফল হয়েছে।",
                "ব্যাক্তিগত তথ্য আপডেট ব্যার্থ হয়েছে , কিছুক্ষন পরে আবার চেষ্টা করুন।"
              );
              setpersonalInfoEditModalShow(false);
            }}
          />
        )}
        {educationEditModalShow && (
          <UserEducationEditModal
            isVisible={educationEditModalShow}
            data={educationInfo}
            educationOptions={educations}
            universityOptions={universitys}
            onHide={() => {
              setEducationEditModalShow(false);
            }}
            updateEducationData={(dt, tempData) => {
              setEducationInfo(tempData);
              updateEducationInfoData(
                dt,
                "শিক্ষাগত যোগ্যতা আপডেট সফল হয়েছে।",
                "শিক্ষাগত যোগ্যতা আপডেট ব্যার্থ হয়েছে , কিছুক্ষন পরে আবার চেষ্টা করুন।"
              );
              setEducationEditModalShow(false);
            }}
          />
        )}
        {preExpEditModalShow && (
          <UserPreExperienceModal
            isVisible={preExpEditModalShow}
            data={preExperienceInfo}
            jobTypeOptions={jobTypes}
            onHide={() => {
              setPreExpEditModalShow(false);
            }}
            updatePreExperienceData={(dt, tempData) => {
              setPreExperienceInfo(tempData);
              updatePreExperienceData(
                dt,
                "পূর্ববর্তী অভিজ্ঞতা আপডেট সফল হয়েছে।",
                "পূর্ববর্তী অভিজ্ঞতা আপডেট ব্যার্থ হয়েছে , কিছুক্ষন পরে আবার চেষ্টা করুন।"
              );
              setPreExpEditModalShow(false);
            }}
          />
        )}
        {courseEditModalShow && (
          <UserCourseModal
            isVisible={courseEditModalShow}
            data={courseInfo}
            courseOptions={courses}
            onHide={() => {
              setCourseEditModalShow(false);
            }}
            updateCourseData={(dt, tempData) => {
              setCourseInfo(tempData);
              updateCourseInfoData(
                dt,
                "কোর্স আপডেট সফল হয়েছে।",
                "কোর্স আপডেট ব্যার্থ হয়েছে , কিছুক্ষন পরে আবার চেষ্টা করুন।"
              );
              setCourseEditModalShow(false);
            }}
          />
        )}
        {skillEditModalShow && (
          <SkillInfoEditModal
            isVisible={skillEditModalShow}
            data={skillInfo}
            skillOptions={skills}
            onHide={() => {
              setSkillEditModalShow(false);
            }}
            updateSkillInfo={(dt, tempData) => {
              setSkillInfo(tempData);
              updateUserSkillInfoData(
                dt,
                "অন্যান্য দক্ষতা আপডেট সফল হয়েছে।",
                "অন্যান্য দক্ষতা আপডেট ব্যার্থ হয়েছে , কিছুক্ষন পরে আবার চেষ্টা করুন।"
              );
              setSkillEditModalShow(false);
            }}
          />
        )}
        {referenceEditModalShow && (
          <UserReferenceModal
            isVisible={referenceEditModalShow}
            data={referanceInfo}
            onHide={() => {
              setReferenceEditModalShow(false);
            }}
            updateReferenceData={(dt, tempData) => {
              setReferenceInfo(tempData);
              updateReferenceInfoData(
                dt,
                "রেফারেন্স আপডেট সফল হয়েছে।",
                "রেফারেন্স আপডেট ব্যার্থ হয়েছে , কিছুক্ষন পরে আবার চেষ্টা করুন।"
              );
              setReferenceEditModalShow(false);
            }}
          />
        )}
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    </>
  );
};

export default Profile;
